.rmc-picker,
.rmc-multi-picker {
  height: 238px;
  /*34*7*/
}
.rmc-multi-picker {
  display: flex;
  align-items: center;
}
.rmc-picker-item {
  font-size: 16px;
  height: 34px;
  line-height: 34px;
  padding: 0 10px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #9b9b9b;
  width: 100%;
  box-sizing: border-box;
}
.rmc-picker {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
  text-align: center;
}
.rmc-picker-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)), linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
  background-position: top, bottom;
  background-size: 100% 204px;
  background-repeat: no-repeat;
}
.rmc-picker-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.rmc-picker-indicator {
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  position: absolute;
  left: 0;
  top: 102px;
  z-index: 3;
  border-top: 1PX solid #ddd;
  border-bottom: 1PX solid #ddd;
}
